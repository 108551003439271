import React from "react"
import { CodeBlockDarkTheme } from "../utils"
import { CodeBlock } from "@atlaskit/code"
const styles = require("./Pages.module.scss");

export default function Authentication() {
  return (
    <React.Fragment>
      <div className={styles.Content}>
        <h1>Authentication</h1>
        <p>
          The Prestavi API supports authentication using a Bearer token. This allows access from your own server. Remember to keep your API key secret. They act on your behalf when interacting with the API. We suggest that you use environment variables instead of hardcoding them in your scripts.
        </p>
        <p>
          You can generate an API key from your Prestavi account by navigating to Manage > Developers > API Keys.
        </p>

        <p>All API requests must be made over HTTPS. API requests without authentication will fail.</p>

        <p>To use your API key, include the Authorization Header with the Bearer scheme.</p>
      </div>
      <div className={styles.UnboundedContent}>
        <CodeBlock theme={CodeBlockDarkTheme} language="text" text={'curl https://us-01.api.prestavi.com/jobs -H "Authorization: Bearer <your API key>"'} showLineNumbers={false} />
      </div>
    </React.Fragment>

  )
}